import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Sidebar from "../components/Sidebar";
import { Table, Button, Modal, Form, Input, Select } from 'antd';
import axios from 'axios';
import withProtectedPage from '../withProtectedPage';
import { API_URL } from '../constants/apiConstants';

const { Option } = Select;  // ✅ Added this line to fix the error


const Students = (props) => {
  const [studentsData, setStudentsData] = useState([]); // Initialize as an empty array
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchStudentsData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(API_URL + "GetStudents.php", {
          headers: {
            token: `${token}`
          }
        });
        setStudentsData(response.data); // Set the fetched data
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error (redirect to login page, display error message, etc.)
      }
    };

    fetchStudentsData();
  }, []); // Empty dependency array means this runs once on mount


  const handleAddStudent = () => {
    setIsModalVisible(true);
};

const handleCancel = () => {
    setIsModalVisible(false);
};


const handleFormSubmit = async (values) => {
  try {
      const token = localStorage.getItem('token');

      // Convert JSON to form-data using URLSearchParams
      const formData = new URLSearchParams();
      Object.keys(values).forEach(key => {
          formData.append(key, values[key]);
      });

      const response = await axios.post(API_URL + "TeacherCreateAccount.php", formData, {
          headers: {
              'token': token,
              'Content-Type': 'application/x-www-form-urlencoded' // Important for form-data
          }
      });

    // ✅ Check for success in the response
     if (response.data.status === "success") {
          alert('Student added successfully!');
          setIsModalVisible(false);
          form.resetFields();
      } else {
          // ✅ If API returns an error message, display it
          alert(response.data.error || 'An error occurred while adding the student.');
      }
  } catch (error) {
      console.error('Error adding student:', error);
      if (error.response) {
        alert(`Error: ${error.response.data.error.message || error.response.statusText}`);
    } else if (error.request) {
        alert('No response received from the server.');
    } else {
        alert('An error occurred while sending the request.');
    }
  }
};


  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Student Ranking',
      dataIndex: 'ranking',
      key: 'ranking',
      sorter: (a, b) => a.ranking - b.ranking,
    },
    {
      title: 'Quiz Average',
      dataIndex: 'quizAverage',
      key: 'quizAverage',
      sorter: (a, b) => a.quizAverage - b.quizAverage,
    },
    {
      title: '# Of Quiz Taken',
      dataIndex: 'quizNbr',
      key: 'quizNbr',
      sorter: (a, b) => a.quizNbr - b.quizNbr,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <div className="dashboard">
      <div className="SideMenu">
        <Sidebar setLoggedIn={props.setLoggedIn} />
      </div>
      <div className="dashboard__content">
        <div className="db_content">
          <h2>My Students</h2>
          <Button type="primary" onClick={handleAddStudent} style={{ marginBottom: '20px' }}>
                        Add a Student
                    </Button>
          <div className="sep"></div>

          <Table
            columns={columns}
            dataSource={studentsData} // Use the fetched data here
            onChange={onChange}
            pagination={{
              defaultCurrent: 1,
              defaultPageSize: 10,
              total: studentsData.length, // Update total to reflect fetched data
              showSizeChanger: true,
              showQuickJumper: true,
            }}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}
          />
        </div>
      </div>

  {/* Modal for Adding a Student */}
  <Modal
                title="Add a New Student"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} onFinish={handleFormSubmit} layout="vertical">
                    <Form.Item name="first_name" label="First Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="last_name" label="Last Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="username" label="Username" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name="level" label="Level" rules={[{ required: true }]}>
                        <Select>
                            {[...Array(10).keys()].map(i => (
                                <Option key={i + 1} value={i + 1}>{i + 1}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="grade" label="Grade" rules={[{ required: true }]}>
                        <Select>
                            {[...Array(12).keys()].map(i => (
                                <Option key={i + 1} value={i + 1}>{i + 1}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

    </div>
  );
};

export default withProtectedPage(Students);